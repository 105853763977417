import { useEffect, useState } from 'react'

function useMain() {
    const [isScrolledUp, setIsScrolledUp] = useState(false);
    
    useEffect(() => {
        const handleScroll = () => {
        const position = window.scrollY;
        // setScrollPosition(position);
        setIsScrolledUp(position >= 200);
        };

        window.addEventListener('scroll', handleScroll);
        
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return {
        isScrolledUp
    }
}

export default useMain