import React from 'react'
import Header from '../component/Header'
import Content from '../component/Content'
import { ArrowRight, ChevronRight } from 'lucide-react'
import { Link } from 'react-router-dom'
import Footer from '../component/Footer'
import { aboutImg1 } from '../assets'
import { images } from '../component/Carousel'

function About() {
  return (
    <div className='w-screeen'>
      <section className="sm:h-[300px] flex flex-col w-full relative">
        <video src='https://cdn.pixabay.com/video/2023/11/29/191354-890134722_large.mp4' autoPlay loop muted className='size-full object-cover'/>
        <div className="absolute size-full left-0 top-0 flex flex-col items-center p-[1rem] bg-[#00000072]">
          <Content children={<Header/>}/>
          <Content className={`h-full`} children={<div className='w-full text-white'>
            <h1 className="text-[3rem] font-bold">About Us</h1>
            <div className="flex gap-2 items-center">
              <Link to={'/#home'}>Home</Link>
              <ChevronRight/>
              <b className='text-sky-400'>About Us</b>
            </div>
            </div>}/>
        </div>
      </section>
      <section className="w-full min-h-[100dvh] flex flex-col items-center py-[2rem]">
        <Content className={``} children={<div className='lg:px-[10rem] w-full'>
          <section className="w-full flex md:flex-row flex-col gap-4 py-[4rem]">
            <div className="flex flex-col items-start">
              <b className='mb-3 text-xl font-bold'>About MG Group</b>
              <p className='mb-2'>At MG Groupe, we are more than just a business—we are a vision brought to life. </p>
              <p className='mb-2'>As a dynamic conglomerate, we operate across floriculture, aquaculture, and environmental restoration, driven by a passion for innovation, sustainability, and excellence. </p>
              <p className='mb-2'>Our impact extends beyond industries to touch lives, empower communities, and foster ecological balance.</p>
            </div>
            <div className="min-w-[300px] lg:min-w-[400px] w-[300px] lg:w-[400px] overflow-hidden rounded-xl shadow-lg">
              <img src={aboutImg1} alt="" className="size-full object-cover" />
            </div>
          </section>

          <section className="w-full flex md:flex-row flex-col-reverse gap-4 py-[4rem]">
            <div className="min-w-[300px] lg:min-w-[400px] w-[300px] lg:w-[400px] overflow-hidden rounded-xl shadow-lg">
              <img src={aboutImg1} alt="" className="size-full object-cover" />
            </div>
            <div className="flex flex-col items-start">
              <b className='mb-3 text-xl font-bold'>Who We Are</b>
              <p className='mb-2'>MG Groupe stands at the intersection of purpose and progress.</p>
              <p className='mb-2'>With roots firmly planted in Ghana and aspirations reaching far beyond, we deliver premium products and services while maintaining a deep commitment to environmental stewardship. </p>
              <p className='mb-2'>By combining cutting-edge technology, expertise, and dedication, we lead initiatives that inspire change and drive success.</p>
            </div>
          </section>


          <section className="flex lg:flex-row flex-col lg:items-start items-center justify-center gap-5 text-center py-[4rem] bg-[#efffef] shadow-xl rounded-lg">
            <aside className="flex flex-col items-center w-[400px]">
              <div className='size-[80px] bg-primary rounded-full mb-2'/>
              <b className='text-lg'>Our Mission</b>
              <p>To provide innovative and sustainable solutions across floriculture, aquaculture, and environmental restoration, while contributing to economic development and ecological harmony in Ghana and beyond.</p>
            </aside>
            <aside className="flex flex-col items-center w-[400px]">
              <div className='size-[80px] bg-primary rounded-full mb-2'/>
              <b className='text-lg'>Our Vission</b>
              <p>To become a globally recognized leader celebrated for excellence in quality, sustainability, and customer satisfaction. We aim to redefine industries and enhance lives through our diverse offerings.</p>
            </aside>
          </section>


          <section className="w-full flex md:flex-row flex-col gap-4 py-[4rem]">
            <div className="flex flex-col items-start">
              <b className='mb-3 text-xl font-bold'>Our Core Values</b>
              <ul>
                <li className='mb-2'>
                  <strong>1. Sustainability:</strong>
                  <span>We integrate environmentally conscious practices into every aspect of our operations, ensuring a greener future.</span>
                </li>
                <li className='mb-2'>
                  <strong>2. Quality:</strong>
                  <span>Our commitment to excellence drives us to deliver products and services that consistently exceed expectations.</span>
                </li>
                <li className='mb-2'>
                  <strong>3. Innovation:</strong>
                  <span>We embrace forward-thinking solutions to solve today’s challenges and shape tomorrow’s opportunities.</span>
                </li>
                <li className='mb-2'>
                  <strong>4. Integrity</strong>
                  <span>Honesty and transparency are the foundation of every relationship we build.</span>
                </li>
                <li className='mb-2'>
                  <strong>5. Community Impact:</strong>
                  <span>We actively contribute to the well-being of the communities we serve through meaningful initiatives.</span>
                </li>
              </ul>
            </div>
            <div className="min-w-[300px] lg:min-w-[400px] w-[300px] lg:w-[400px] overflow-hidden rounded-xl shadow-lg">
              <img src={aboutImg1} alt="" className="size-full object-cover" />
            </div>
          </section>


          <section className="w-full flex gap-4 py-[4rem]">
            <div className="image-container animate hidden grid-cols-3 sm:w-[420px] sm:min-w-[420px] text-white">
              <aside className="w-full h-[120px] p-4 bg-transparent flex items-center justify-center"><b className="text-lg text-black">Our top picks</b></aside>
              <aside className="w-full h-[120px] border border-primary">
                  <img src={images[0].image} alt="" className="size-full object-cover" />
              </aside>
              <aside className="w-full h-[120px] bg-primary border border-primary p-4">
                  <p className='leading-[0.1rem text-[0.7rem]'>In publishing and graphic design, Lorem ipsum is a placehold</p>
              </aside>
              <aside className="w-full h-[120px] border border-primary">
                  <img src={images[4].image} alt="" className="size-full object-cover" />
              </aside>
              <aside className="w-full h-[120px] bg-primary border border-primary p-4">
                  <p className='leading-[0.1rem text-[0.7rem]'>In publishing and graphic design, Lorem ipsum is a placehold</p>
              </aside>
              <aside className="w-full h-[120px] border border-primary p-4"></aside>
              <aside className="w-full h-[120px] p-4"></aside>
              <aside className="w-full h-[120px] p-4"></aside>
              <aside className="w-full h-[120px] border border-primary">
                  <img src={images[5].image} alt="" className="size-full object-cover" />
              </aside>
            </div>
            <div className="flex flex-col items-start w-full">
              <b className='mb-3 text-xl font-bold'>What We Do</b>
              <div className="flex w-full overflow-hidden overflow-x-auto">
                <div className='flex gap-[1.5rem]'>
                  <li className='mb-2 flex flex-col border rounded-xl p-4 shadow-lg w-[250px]'>
                    <strong>1. MG Roses:</strong>
                    <span className='text-sm leading-6'>At MG Roses, we cultivate and supply premium-quality roses from our state-of-the-art greenhouse in Aburi. Whether for retail or wholesale, our roses are carefully nurtured to ensure vibrant colors, long-lasting freshness, and unmatched beauty. MG Floral is the go-to partner for florists, event planners, and individuals seeking the perfect bloom for any occasion.</span>
                    <button className="flex items-center text-sm mt-2 text-sky-600 font-semibold gap-2">
                      Visit Website 
                      <ArrowRight className='size-4'/>
                    </button>
                    
                  </li>
                  <li className='mb-2 flex flex-col border rounded-xl p-4 shadow-lg w-[250px]'>
                    <strong>2. MG Aquaculture:</strong>
                    <span className='text-sm leading-6'>MG Aquaculture focuses on the sustainable production and wholesale supply of catfish and tilapia. Our fish are raised in pristine conditions in our dedicated dam in Aburi, ensuring they are healthy, nutritious, and of the highest quality. We cater to households, restaurants, and businesses, providing fresh, flavorful fish that meet the demands of Ghana's growing market.</span>
                    <button className="flex items-center text-sm mt-2 text-sky-600 font-semibold gap-2">
                      Visit Website 
                      <ArrowRight className='size-4'/>
                    </button>
                  </li>
                  <li className='mb-2 flex flex-col border rounded-xl p-4 shadow-lg w-[250px]'>
                    <strong>3. MG Seedling and Tree Planting:</strong>
                    <span className='text-sm leading-6'>MG Seedling and Tree Planting is committed to environmental sustainability and reforestation. We produce and supply tree seedlings such as mahogany, cedrela odurata, odum, eucalyptus, emere, and manzonia for reforestation, ornamental planting, and commercial use. Additionally, we provide expert consultancy services in land reclamation, helping clients restore ecosystems and promote sustainable land use.</span>
                    <button className="flex items-center text-sm mt-2 text-sky-600 font-semibold gap-2">
                      Visit Website 
                      <ArrowRight className='size-4'/>
                    </button>
                  </li>
                </div>
              </div>
            </div>
          </section>

          <section className="sm:p-8 rounded-2xl sm:bg-[#0d570d] sm:text-white sm:text-base text-sm w-full">
            <div className="w-[200px] overflow-hidden rounded-xl shadow-lg mb-4">
              <img src={aboutImg1} alt="" className="size-full object-cover" />
            </div>
            <aside className="flex flex-col w-full">
              <h1 className='font-bold sm:text-white text-[#0d570d] text-lg sm:text-2xl mb-2'>Why Choose MG Groupe?</h1>
              <div className="overflow-x-auto max-w-full">
                <ul className="flex gap-7">
                  <li className="mb-2 w-[250px] text-left p-3 rounded-xl sm:shadow-none shadow-xl border bg-white text-black min-h-[250px] text-sm flex flex-col gap-2 flex-shrink-0">
                    <strong className="sm:text-green-700 mb-3 mr-1">Unmatched Quality:</strong>
                    <p>Whether it’s our stunning roses, fresh fish, or robust seedlings, our products set the standard for excellence.</p>
                  </li>
                  <li className="mb-2 w-[250px] text-left p-3 rounded-xl sm:shadow-none shadow-xl border bg-white text-black min-h-[250px] text-sm flex flex-col gap-2 flex-shrink-0">
                    <strong className="sm:text-green-700 mb-3 mr-1">Sustainability at the Core:</strong>
                    <p>Every step we take is geared towards preserving the environment for generations to come.</p>
                  </li>
                  <li className="mb-2 w-[250px] text-left p-3 rounded-xl sm:shadow-none shadow-xl border bg-white text-black min-h-[250px] text-sm flex flex-col gap-2 flex-shrink-0">
                    <strong className="sm:text-green-700 mb-3 mr-1">Industry Expertise:</strong>
                    <p>With years of experience and a dedicated team, we deliver tailored solutions that cater to the unique needs of our clients.</p>
                  </li>
                  <li className="mb-2 w-[250px] text-left p-3 rounded-xl sm:shadow-none shadow-xl border bg-white text-black min-h-[250px] text-sm flex flex-col gap-2 flex-shrink-0">
                    <strong className="sm:text-green-700 mb-3 mr-1">Customer-Centric Approach:</strong>
                    <p>We prioritize customer satisfaction, ensuring a seamless and rewarding experience every time.</p>
                  </li>
                </ul>
              </div>
            </aside>
            
            <br /><br />
            <h1 className='px-6 min-h-12 w-fit flex items-center rounded-xl bg-green-950 text-white font-bold mb-2'>Our Impact</h1>
            <p>From supporting local economies to championing eco-friendly practices, MG Groupe is committed to creating a lasting legacy. We believe that sustainable growth is the cornerstone of progress, and through our initiatives, we aim to uplift communities and protect the planet.</p>

            <br /><br />
            <h1 className='px-6 min-h-12 w-fit flex items-center rounded-xl bg-green-950 text-white font-bold mb-2'>Join Us</h1>
            <p>MG Groupe invites you to partner with us in shaping a future defined by innovation, sustainability, and shared success. Together, we can build a greener, more beautiful world—one step at a time.</p>
            <p className='my-2 text-sm text-green-950'>Let’s grow together. Let’s make a difference.</p>
          </section>


        </div>}/>
      </section>
      <Footer/>
    </div>
  )
}

export default About