import React, { useState } from 'react'

export const verifyType = (file)=>{
    if(file){
        try{
            if(`${file}`?.includes('mp4')){
                return 'video';
            }else return 'image';
        }catch(err){
            console.log(err)
        }
    }
}

function GalleryView({list, active}) {
    const [current, setCurrent] = useState(active||0);
    const forward = () => {
        setCurrent(prev=>prev < list?.length ? prev+1 : 0)
    };
    const backward = () => {
        setCurrent(prev=>prev <= 0 ? prev-1 : list.length-1)
    };
    return (
        <div className='w-full xl:w-[1000px] h-[70dvh] md:h-[700px] relative place-items-center overflow-hidden bg-black rounded-3xl'>
            <section className="absolute top-[50%] left-0 w-full flex items-center justify-between z-10 px-4">
                <button onClick={()=>backward()} className='size-10 bg-white text-black rounded-full'><i className="fa fa-chevron-left"></i></button>
                <button onClick={()=>forward()} className='size-10 bg-white text-black rounded-full'><i className="fa fa-chevron-right"></i></button>
            </section>
            {
                verifyType(list[current]?.file) === 'image' ? <img src={list[current]?.file} alt='gallery' className='w-full h-full object-cover'/>:
                <video src={list[current]?.file} loop autoPlay muted className='w-full h-full object-cover'/>
            }
        </div>
    )
}

export default GalleryView