import React from 'react'
import { logoblack, mgAquaculture, mgFloral, mgSeedlings } from '../assets'
import Content from './Content'
import { ArrowRight } from 'lucide-react'

function Services() {
    return <section id='services' className="w-full bg-white py-[5rem] md:py-[10rem] px-2 flex flex-col items-center justify-center place-items-center relative">
            <div className='absolute size-full object-contain top-0 left-0 flex justify-center items-center opacity-[0.035]'>
                <img src={logoblack} alt="logo watermark" className='size-[80%] object-contain' />
            </div>
            <div className="w-full h-fit flex flex-col z-10">
                <h1 className="text-[3rem] font-bold">Our Services</h1>
                <p className='sm:text-base text-sm'>At <span className="text-primary font-semibold">MirusGrace,</span> we provide three(3) amazing services that boost the agriculture industry productivity.</p>
                <p className='sm:text-base text-sm'>For each service, you can explore more about them to reach out to our <span className="text-sky-600">customer service.</span></p>
            </div>
            <Content className={`mt-[3rem] h-fit z-10`} children={<section className='w-full flex justify-center flex-wrap gap-[2rem]'>
                <div className="w-[300px] h-[400px] flex flex-col items-center">
                    <aside className="size-24 mb-4 rounded-full flex items-center justify-center bg-secondary text-primary">
                    {/* <FishSymbol className='size-8 -rotate-45'/> */}
                    <img src={mgAquaculture} alt='aquaculture' className='size-full object-contain'/>
                    </aside>
                    <b className='text-primary text-lg mb-2'>Mg-AquaCulture</b>
                    <small className='text-slate-700'>At MG Aquaculture, we provide premium-quality catfish and tilapia through sustainable aquaculture practices. Located in Aburi, our state-of-the-art dam ensures a healthy environment for raising nutritious fish. We prioritize sustainability, food safety, and fish wellbeing, overseeing the entire process from breeding to harvesting. MG Aquaculture guarantees fresh, flavorful products for both household and commercial needs. Join us in supporting sustainable fish farming in Ghana.</small>
                    <a href="https://aqua-culture.mirusgrace.com" target='_blank' rel="noreferrer">
                        <button className="px-10 h-10 rounded-3xl bg-primary text-white text-sm border border-primary mt-2 flex items-center gap-3">
                            Read More
                            <ArrowRight className='size-5 -rotate-45'/>
                        </button>
                    </a>
                </div>
                <div className="w-[300px] h-[400px] flex flex-col items-center">
                    <aside className="size-24 mb-4 rounded-full flex items-center justify-center bg-secondary text-primary">
                    {/* <TreeDeciduous className='size-8 -rotate-45'/> */}
                    <img src={mgSeedlings} alt='seedlings' className='size-full object-contain'/>
                    </aside>
                    <b className='text-primary text-lg mb-2'>Mg-Seedlings</b>
                    <small className='text-slate-700'>At MG Seedling and Tree Planting, we are dedicated to promoting a greener future through the supply of high-quality seedlings and tree planting services across Ghana. With a focus on sustainability, reforestation, and environmental preservation, we work to improve the natural landscape and combat climate change. Our expertise includes cultivating and providing a variety of tree species, such as Mahogany, Cedrela Odurata, Odum, Eucalyptus, Emere, and Manzonia.</small>
                    <a href="https://seedlings.mirusgrace.com" target='_blank' rel="noreferrer">
                        <button className="px-10 h-10 rounded-3xl bg-primary text-white text-sm border border-primary mt-2 flex items-center gap-3">
                            Read More
                            <ArrowRight className='size-5 -rotate-45'/>
                        </button>
                    </a>
                </div>
                <div className="w-[300px] h-[400px] flex flex-col items-center">
                    <aside className="size-24 mb-4 rounded-full flex items-center justify-center bg-secondary text-primary">
                    {/* <FlowerIcon className='size-8 -rotate-45'/> */}
                    <img src={mgFloral} alt='floral' className='size-full object-contain'/>
                    </aside>
                    <b className='text-primary text-lg mb-2'>Mg-Floral</b>
                    <small className='text-slate-700'>MG Floral is a leading supplier and producer of high-quality roses, cultivated with care in a state-of-the-art greenhouse in Aburi. Focused on sustainability and innovation, they use advanced greenhouse techniques to produce vibrant, long-lasting roses for florists, event planners, and flower enthusiasts across Ghana and beyond. MG Floral is dedicated to delivering excellence in every petal, whether for special occasions, wholesale orders, or floral supplies.</small>
                    <a href="https://floral.mirusgrace.com" target='_blank' rel="noreferrer">
                        <button className="px-10 h-10 rounded-3xl bg-primary text-white text-sm border border-primary mt-2 flex items-center gap-3">
                            Read More
                            <ArrowRight className='size-5 -rotate-45'/>
                        </button>
                    </a>
                </div>
            </section>}/>
        </section>
}

export default Services