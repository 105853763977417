import React from 'react'

import Content from '../component/Content.jsx';
import { videos } from '../constants.js';
import { images } from '../component/Carousel.jsx';
import Banner from '../component/Banner.jsx';
import Modal from '../component/Modal.jsx';
import { useState } from 'react';
import GalleryView, { verifyType } from '../component/GalleryView.jsx';
import { logo, logowhite } from '../assets/index.js';
import useDevice from '../hooks/useDevice.js'
import Footer from '../component/Footer.jsx';
import Contact from '../component/Contact.jsx';
import Services from '../component/Services.jsx';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const modalType = {
    'gallery': 'Our Gallery'
}

function HomePage() {
    const [modal, setModal] = useState('');
    const [gallery, setGallery] = useState(0);
    const {os} = useDevice();

    return <div className="App flex flex-col items-center w-screen h-screen overflow-x-hidden">
        <Modal name={modal} close={()=>setModal('')} content={<>
            {
                modal === modalType.gallery ? <GalleryView active={gallery} list={videos}/> : null
            }
            </>}/>
        <div className="w-full lg:min-h-[100dvh] bg-white">
            <Banner/>
        </div>

        <section id='about' className="w-full bg-secondary py-[5rem] md:py-[10rem] flex justify-center border-b-2 border-primary">
            <Content children={<section className='flex lg:flex-row flex-col items-center justify-between text-left gap-[2rem]'>
                <div className="flex flex-col sm:w-[500px] lg:text-left text-center">
                    <h1 className="text-[2.5rem] sm:text-[3rem] leading-[3rem] sm:leading-[3.5rem] font-extrabold lg:w-[500px]">Real Positive Impact on Environment</h1>
                    <br />
                    <p className='text-sm mb-2'>
                        At MG Groupe, we pride ourselves on being a catalyst for positive change, blending innovation with sustainability to deliver exceptional value across multiple industries. With a foundation built on excellence, integrity, and environmental responsibility, we aim to transform lives, communities, and ecosystems.
                    </p>
                    <Link to={`/about`} className=''>
                        <button className="w-fit lg:text-base text-sm lg:mx-0 mx-auto px-5 lg:h-12 h-10 border border-primary text-primary rounded-[5rem] mb-4 mt-1 flex items-center gap-3">
                            Read More 
                            <ArrowRight/>
                        </button>
                    </Link>
                    <div id='mission' className="flex flex-col gap-2 mb-3">
                        <b className='text-lg'>Our Mission</b>
                        <p className="text-sm">
                            To pioneer innovative and sustainable solutions in floriculture, aquaculture, and environmental restoration, contributing to economic growth and ecological harmony in Ghana and beyond.
                        </p>
                    </div>
                    <div id='vission' className="flex flex-col gap-2">
                        <b className='text-lg'>Our Vission</b>
                        <p className="text-sm">
                            To be a globally recognized conglomerate celebrated for our commitment to quality, sustainability, and customer satisfaction, shaping industries and enriching lives with our diverse range of products and services.
                        </p>
                    </div>
                </div>
                <div className="image-container animate grid grid-cols-3 sm:w-[420px] text-gray-300">
                    <aside className="w-full h-[120px] p-4 bg-transparent flex items-center justify-center"><b className="text-lg text-primary">In Business</b></aside>
                    <aside className="w-full h-[120px] bg-primary border border-primary p-4  flex items-center justify-center text-center">
                        <b>Climate Impact</b>
                    </aside>
                    <aside className="w-full h-[120px] border border-primary p-4  flex items-center justify-center text-center">
                        <b className='text-black text-[2rem]'>60%</b>
                    </aside>
                    <aside className="w-full h-[120px] bg-primary border border-primary p-4  flex items-center justify-center text-center">
                        <b>Climate Impact</b>
                    </aside>
                    <aside className="w-full h-[120px] border border-primary p-4  flex items-center justify-center text-center">
                        <b className='text-black text-[2rem]'>60%</b>
                    </aside>
                    <aside className="w-full h-[120px] border border-primary">
                        <img src={images[0].image} alt="" className="size-full object-cover" />
                    </aside>
                    <aside className="w-full h-[120px] border border-primary">
                        <img src={images[4].image} alt="" className="size-full object-cover" />
                    </aside>
                    <aside className="w-full h-[120px] p-4"></aside>
                    <aside className="w-full h-[120px] p-4 flex items-center justify-center border border-primary">
                    <img src={logo} alt="" className="w-20" />
                    </aside>
                </div>
            </section>}/>
        </section>

        <Services/>
        
        <section id='gallery' className="w-full py-[5rem] md:py-[10rem] bg-black flex flex-col items-center text-white">
            <div className="text-[3rem] font-bold flex items-center gap-3"><span><img src={logowhite} alt="" className="w-16" /></span> <span>Gallery</span></div>
            <p className='sm:text-base text-sm'>At <span className="text-primary font-semibold">MirusGrace,</span> we engage our lovely people with the excitements of our achievements and growth.</p>
            <p className='sm:text-base text-sm'>The media files here can be viewed by just a click. 😊</p>
            
            <Content children={<section className="w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-3 mt-10 overflow-x-auto ">
            {
                videos.map((item, idx)=>{
                return <div key={`gallery-${idx}`} onClick={()=>{
                    setGallery(idx);
                    setModal(modalType.gallery)
                }} className="w-full h-400px relative group">
                {
                    (verifyType(item.file) === 'image') ? <img src={item.file} alt='gallery' className='w-full h-full object-cover'/>:
                    <video src={item.file} loop autoPlay={os !== 'iOS'} muted className='w-full h-full object-cover'/>
                }
                </div>
                })
            }
            </section>
        }/>
        </section>

        <Contact/>

        <Footer/>

    </div>
}

export default HomePage