import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ArrowRight, Mail, MapPin, Phone } from "lucide-react";
import Content from "./Content";
import Button from "./Button";
import useMain from "../hooks/useMain";

export const images = [
  {
      image: 'https://media.istockphoto.com/id/108350472/photo/african-girl-planting-mango-tree.jpg?s=612x612&w=0&k=20&c=rIQGMcHiKdh7Mb_db8kP6Fnc_ibBAY7H39EsXh6k8TM=',
      headline: "Building the Future",
      subtext: "Innovative construction solutions for a better tomorrow.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nihil, quasi?",
  },
  {
      image: "https://png.pngtree.com/thumb_back/fw800/background/20240412/pngtree-huge-south-african-trees-in-kirstenbosch-botanical-garden-cape-town-image_15656410.jpg",
      headline: "Strong Foundations",
      subtext: "Delivering quality projects with precision and care.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nihil, quasi?",
  },
  {
      image: "https://african.business/wp-content/uploads/2023/11/adobestock_592986613-1200x800.webp",
      headline: "Strong Foundations",
      subtext: "Delivering quality projects with precision and care.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nihil, quasi?",
  },
  {
      image: 'https://www.saint-gobain-africa.com/sites/mac3.saint-gobain-africa.com/files/styles/media_default/public/2023-11/istockphoto-1297831482-612x612.jpg.webp?itok=YYwVs4uw',
      headline: "Strong Foundations",
      subtext: "Delivering quality projects with precision and care.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nihil, quasi?",
  },
  {
  image: 'https://media.istockphoto.com/id/1322302356/photo/foreman-laughing.jpg?s=612x612&w=0&k=20&c=v1mSEbE_5NENwNLX8iowVa1vGV39s2NpV2xcTdy8ATU=',
      headline: "Building the Future",
      subtext: "Innovative construction solutions for a better tomorrow.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nihil, quasi?",
  },
  {
    image: "https://nairametrics.com/wp-content/uploads/2022/04/Career-in-Project-Management.jpg",
    headline: "Sustainable Designs",
    subtext: "Creating eco-friendly structures for generations to come.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nihil, quasi?",
  },
]
const Carousel = () => {
    const {isScrolledUp} = useMain();
    const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    // dots: true, // Show navigation dots
    infinite: true, // Loop through slides
    speed: 1000, // Transition speed
    slidesToShow: 1, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll at once
    autoplay: true, // Enable autoplay
    autoplaySpeed: 5000, // Autoplay interval
    beforeChange: (_, next) => setCurrentSlide(next),
  };


//   const locationNotHome = ()=>!(window.location.href.includes('home') || window.location.href === '/')

    return (
        <div className="relative overflow-hidden z-20" style={{ width: "100vw", height: "100vh", overflow: "hidden" }}>
            <header className={`flex min-h-[70px] items-center justify-center w-screen z-20 top-0 bg-green-700 text-white ${isScrolledUp && 'fixed top-0 left-0 bg-white shadow-lg'}`}>
                <Content children={<section className="w-full flex items-center justify-between">
                    <div className="flex items-center">
                    <i className="fab fa-pinterest mr-3 text-[2.5rem]"/>
                    <button className="flex items-center px-4 h-10 rounded-3xl bg-gray-100 text-black">
                        <i className="fa fa-bars mr-2 text-sm"></i>
                        <b className="text-sm">Explore</b>
                    </button>
                    </div>
                    <div className="w-[280px] lg:w-[350px] hidden sm:flex items-center justify-between text-sm">
                    <a href="#home" className=''>Home</a>
                    <a href="#projects" className=''>Projects</a>
                    <a href="#about" className=''>About us</a>
                    <a href="#services" className=''>Services</a>
                    </div>
                    <Button text={`Contact us`}/>
                </section>}/>
            </header>
            <div className="w-full px-[200px] flex justify-end absolute bottom-0 z-20 ">
                <section className="p-4 bg-green-900 text-white w-[300px] rounded-t-lg border-t-2 border-primary">
                    <div className="flex items-center mb-6">
                        <h1 className="text-xl font-semibold">Contact Us</h1>
                        <Phone className="ml-2 text-slate-500 size-5"/>
                    </div>
                    <div className="my-3 flex items-center">
                        <MapPin strokeWidth={1} className="mr-3 text-primary size-4"/>
                        <small className="font-[300]">East Legon, Greater Accra, Ghana</small>
                    </div>
                    <div className="my-3 flex items-center">
                        <Phone strokeWidth={1} className="mr-3 text-primary size-4"/>
                        <small className="font-[300]">+233542893124</small>
                    </div>
                    <div className="my-3 flex items-center">
                        <Mail strokeWidth={1} className="mr-3 text-primary size-4"/>
                        <small className="font-[300]">info@novuslogisticsgh.com</small>
                    </div>
                    <div className="mt-5 flex items-center">
                        <i className="fab fa-whatsapp size-8 text-sm mr-3 bg-white text-slate-700 flex items-center justify-center rounded-full"></i>
                        <i className="fab fa-facebook size-8 text-sm mr-3 bg-white text-slate-700 flex items-center justify-center rounded-full"></i>
                        <i className="fab fa-instagram size-8 text-sm mr-3 bg-white text-slate-700 flex items-center justify-center rounded-full"></i>
                        <i className="fab fa-linkedin size-8 text-sm mr-3 bg-white text-slate-700 flex items-center justify-center rounded-full"></i>
                    </div>
                </section>
            </div>
            <Slider infinite {...settings} className="absolute top-0 left-0">
                {images.map((image, index) => (
                    <div key={index} className={`h-[100dvh] w-full relative`}>
                        <div className="px-[200px] absolute size-full bg-gradient-to-r text-white from-black via-[#000000b4] z-10 text- p-5 flex flex-col justify-center">
                            <div className="flex flex-col items-start h-[50%] text-left">
                                <aside className="flex flex-col mb-4">
                                    <div className="flex items-center mb-2">
                                        {[...Array(20)].map(item=><div key={item} className={`size-[0.6rem] rounded-full bg-green-700 mr-3 text-container ${currentSlide===index?'animate':''}`}/>)}
                                    </div>
                                    <div className="flex items-center mb-2">
                                        {[...Array(16)].map(item=><div key={item} className={`size-[0.5rem] rounded-full bg-green-500 mr-3 text-container ${currentSlide===index?'animate':''}`}/>)}
                                    </div>
                                    <div className="flex items-center mb-2">
                                        {[...Array(13)].map(item=><div key={item} className={`size-[0.4rem] rounded-full bg-green-300 mr-3 text-container ${currentSlide===index?'animate':''}`}/>)}
                                    </div>
                                </aside>
                                <h1 className="text-primary text-lg z-10">Mirus Grace ...</h1><br />
                                <p className={`text-container ${currentSlide===index?'animate':''} z-10  font-bold text-[5rem] text-green-700 leading-[5.5rem]`}>{image.headline}</p>
                                <p className={`text-container ${currentSlide===index?'animate':''} z-10 font-[300] my-4`}>{image.subtext}</p>
                                <br />
                                <div className="flex gap-5">
                                    <button className="w-[200px] h-12 border-2 rounded-lg bg-green-700 border-green-700 z-10 shadow-xl border-primary mt-4 flex items-center justify-center">Explore More <ArrowRight className="size-4 ml-4"/></button>
                                    <button className="w-[200px] h-12 border-2 rounded-lg bg-[#00000047] border-green-700 z-10 shadow-xl border-primary mt-4 hover:bg-primary flex items-center justify-center">Contact Us</button>
                                </div>
                            </div>
                        </div>
                        <img src={image.image} alt={`Slide ${index + 1}`} className={`image-container ${currentSlide===index?'animate':''} size-full object-cover z-0`}/>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default Carousel;
