export const flowerSamples = [
    'https://images.unsplash.com/photo-1474649111648-d95d30755186?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    'https://www.jotform.com/blog/wp-content/uploads/2022/02/niko-photos-tGTVxeOr_Rs-unsplash.jpg',
    'https://d1l57x9nwbbkz.cloudfront.net/files/s3fs-public/styles/article_masthead/public/2024-04/tasc-tulip-pick-farm.jpg.webp?VersionId=VgsZqZAuIvSC20xUKzCLFacvRizSDgh7&itok=1Aui-M40',
]

export const videos = [
  { name: "Video 1", file: "https://cdn.pixabay.com/video/2018/05/26/16453-272487468_large.mp4" },
  { name: "Video 2", file: "https://media.istockphoto.com/id/515684423/video/poppy-flowers.mp4?s=mp4-640x640-is&k=20&c=_hJ7YVin92aFZzaeZDO8mY-pSkHpNw6aFMBBLq6z0Ok=" },
  { name: "Video 3", file: "https://cdn.pixabay.com/video/2020/02/05/31953-389724688_large.mp4" },
  { name: "Video 4", file: "https://media.istockphoto.com/id/2164911594/video/close-up-brown-pellets-feeds-for-fish-on-hand.mp4?s=mp4-640x640-is&k=20&c=mPvKBk3968TmH3vXui5cm6yIwQV3SbSFw2yo4W4IQsI=" },
  { name: "Video 5", file: "https://cdn.pixabay.com/video/2020/06/23/42931-434316654_large.mp4" },
  { name: "Video 6", file: "https://cdn.pixabay.com/video/2023/11/19/189813-887078786_large.mp4" },
  { name: "Video 7", file: "https://cdn.pixabay.com/video/2023/06/28/169249-840702546_large.mp4" },
  { name: "Video 8", file: "https://cdn.pixabay.com/video/2023/11/29/191354-890134722_large.mp4" },
  { name: "Video 9", file: "https://cdn.pixabay.com/video/2023/10/25/186405-877993676_large.mp4" }
];
