import React from 'react'
import Content from './Content'
import { logo } from '../assets'
import { ArrowRight, Facebook, Instagram, Linkedin, MapPin, Phone } from 'lucide-react'
import { flowerSamples } from '../constants'

function Footer() {
  return <div className="flex justify-center w-full py-10 items-center bg-black text-white">
            <Content children={<>
                <footer className='flex lg:flex-row flex-col lg:gap=0 gap-14 justify-between w-full py-10 lg:items-start items-center bg-black text-white'>
                <div className="flex flex-col lg:items-start items-center w-[300px] lg:text-left">
                    <img src={logo} alt='logo' className='size-16 object-contain'/>
                    <div className='text-base italic font-semibold flex items-center gap-2 -mt-3'>
                    <span>Invest .</span>
                    <span>Buy .</span>
                    <span>Plant</span>
                    </div>
                    <small className="my-5 text-xs leading-5 text-slate-400">
                    We provide the best products in fishing, roses and tree seedlings that are we treated and controlled for sustainable development.
                    </small>

                    <div className="flex items-center gap-2 my-2">
                    <MapPin className='size-4'/>
                    <small className='text-primary'>Koforidua, Ghana</small>
                    </div>
                    <div className="flex items-center gap-2 my-2">
                    <Phone className='size-4'/>
                    <small className='text-primary'>+233542893125</small>
                    </div>
                    <section className="flex items-center gap-2 my-2">
                    <Instagram className='size-5'/>
                    <Facebook className='size-5'/>
                    <Linkedin className='size-5'/>
                    </section>
                </div>
                <section className="flex sm:flex-row flex-col gap-6 sm:gap-16 xl:gap-24 text-left">
                    <div className="flex flex-col lg:items-start items-center">
                    <p className="font-semibold mb-4">Products</p>
                    <small className='mb-2 text-slate-400'>MG - Floral</small>
                    <small className='mb-2 text-slate-400'>MG - Aqua Culture</small>
                    <small className='mb-2 text-slate-400'>MG - Seedlines</small>
                    </div>
                    <div className="flex flex-col lg:items-start items-center">
                    <p className="font-semibold mb-4">Links</p>
                    <small className='mb-2 text-slate-400'>About Us</small>
                    <small className='mb-2 text-slate-400'>Contact Us</small>
                    <small className='mb-2 text-slate-400'>Our Services</small>
                    </div>
                    <div className="flex flex-col lg:items-start items-center">
                    <p className="font-semibold mb-4">Gallery</p>
                    <section className="grid grid-cols-2 gap-2 w-[170px]">
                        {
                        flowerSamples.slice(0,4).map((item, idx)=><aside>
                            <img src={item} alt="" className="size-full object-cover" />
                        </aside>)
                        }
                        <div className="flex items-center gap-2 text-primary">
                        <small>View All</small>
                        <ArrowRight className='size-4 bg-white rounded-full '/>
                        </div>
                    </section>
                    </div>
                </section>
                </footer>
            </>}/>
        </div>
}

export default Footer