import { ArrowRight, Facebook, Instagram, Linkedin } from 'lucide-react'
import React from 'react'
import { images } from './Carousel'
import { logoblack } from '../assets'

function Contact() {
    return <section id='contact' className="w-full min-h-[1000px] text-white relative">
            <video autoPlay loop muted src="https://cdn.pixabay.com/video/2023/11/19/189813-887078786_large.mp4" className="size-full object-cover"/>
            <div className="absolute h-full top-0 left-0 w-full bg-[#000000b5] flex justify-center items-center flex-col">
                <div className="image-container animate grid grid-cols-2 lg:grid-cols-4 w-full xl:w-[1000px] text-gray-300 px-5">
                    <aside className="w-full h-[150px] lg:h-[170px] p-4 bg-transparent flex items-center justify-center"><b className="text-lg lg:text-2xl font-extrabold text-gray-300 text-left">Contact Our Support Team</b></aside>
                    <aside className="w-full h-[150px] lg:h-[170px] border border-primary">
                        <img src={images[4].image} alt="" className="size-full object-cover" />
                    </aside>
                    <aside className="w-full h-[150px] lg:h-[170px] border bg-[#ffffff1a] border-primary"><div className='size-full blur-m flex flex-col justify-center items-center bg-[#ffffff1a]'>
                    <img src={logoblack} className='size-[50%] object-contain blur-0' alt="" />
                    <small>Mirus Grace</small>
                    </div>
                    </aside>
                    <aside className="w-full lg:h-[170px] p-4"></aside>
                    <aside className="w-full lg:h-[170px] border border-primary hidden lg:flex">
                        <img src={images[4].image} alt="" className="size-full object-cover" />
                    </aside>
                    <aside className="w-full lg:h-[170px] bg-primary border border-primary p-4  hidden lg:flex items-center justify-center text-center">
                        <b className='text-left'>Our support team work tirelessly to make an impact</b>
                    </aside>
                    <aside className="w-full lg:h-[170px] border border-primary hidden lg:flex">
                        <img src={images[4].image} alt="" className="size-full object-cover" />
                    </aside>
                    <aside className="w-full lg:h-[170px] bg-primary border border-primary p-4  hidden lg:flex items-center justify-center text-center">
                        <b className='text-left'>We check on regular bases to catch up with people we impact</b>
                    </aside>
                </div>
                <div className="w-full flex flex-col py-[5rem] items-center px-6">
                    <div className="flex flex-col gap-6 items-center w-full sm:w-[500px]">
                        <div className="w-full flex flex-col">
                            <h1 className="text-[3rem] font-bold">Contact Us</h1>
                            <p>Our support team are always ready to service you anytime at your convenience. Send us an email with the form below or follow our social media channels.</p>
                            <div className="my-3 flex gap-2 items-center mx-auto">
                                <Instagram/>
                                <Facebook/>
                                <Linkedin/>
                            </div>
                        </div>
                        <div className="flex items-center border h-12 w-full border-gray-600 rounded-3xl bg-[#ffffff14] overflow-hidden">
                            <input type="text" placeholder='Enter your email' className="h-full w-full font-semibold px-6 text-sm border-none outline-none bg-transparent text-white" />
                            <button className="size-12 min-w-12 rounded-full flex items-center justify-center bg-white text-black">
                            <ArrowRight/>
                            </button>
                        </div>
                        <div className="w-full rounded-3xl border border-gray-600 h-20 relative bg-[#ffffff17] overflow-hidden">
                            <p className="absolute top-2 right-4">🙂‍↔️</p>
                            <textarea name="message" id="contact-message" placeholder='Type your message here...' className="min-h-full max-h-full text-sm w-full p-6 py-3 bg-transparent outline-none border-none text-slate-300"></textarea>
                        </div>
                        <button className="w-[200px] h-14 font-semibold rounded-[3rem] bg-white hover:bg-white transition-all border-2 border-black text-black">Submit</button>
                    </div>
                </div>
            </div>
        </section>
}

export default Contact