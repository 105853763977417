import React from 'react'
import { Route, Routes } from 'react-router-dom'
import About from './pages/About'
import HomePage from './pages/Home'

function Routers() {
  return <Routes>
    <Route path='/' element={<HomePage/>}/>
    <Route path='/about' element={<About/>}/>
  </Routes>
}

export default Routers