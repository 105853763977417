import React, {  } from 'react'
import Content from './Content'
import { images } from './Carousel'
import { Facebook, Fish, Flower, Instagram, Linkedin, TreePine } from 'lucide-react'
import Header from './Header'

function Banner() {
    return (
        <div className='w-screen h-fit lg:h-[100dvh] flex flex-col items-center relative overflow-hidden'>
            <div className="w-full h-full absolute">
                <img src={`https://images.ctfassets.net/kc2xclar6nri/3gZXFBCqg5PuJgjcfQEvtZ/38a8756a38b1076eb538ad64533d3a5f/36473113002_5795d5d14f_h.jpg?w=1472&h=750&fit=fill`} alt="" className="size-full object-cover" />
                <div className='absolute size-full scale-[500%] top-0 left-0 bg-gradient-to-br from-[black] via-[#000000bf] bg-animate'/>
            </div>
            <div className="h-fit lg:size-full lg:gap-0 gap-[10rem] z-10 flex flex-col justify-between items-center pt-[2rem]">
                <Content children={<Header/>}/>
                
                <Content className="h-full -mt-[3.5rem] lg:mt-0" children={<section className='w-full lg:h-auto h-fit flex lg:flex-row flex-col lg:items-center justify-center lg:justify-between gap-[4rem] lg:gap-6 text-left'>
                        <div className="w-full flex flex-col text-white">
                            <h1 style={{}} className="text-container animate text-[2rem] leading-[2.5rem] sm:text-[4rem] lg:text-[5rem] sm:leading-[4.5rem] lg:leading-[5.5rem] font-extrabold w-[80%] sm:w-auto xl:w-[550px]">Create sustainable future</h1>
                            <b className='image-container animate my-2 flex items-center md:text-base text-sm'><i className="fa fa-arrow-right mr-2 size-7 md:size-10 flex justify-center items-center rounded-full bg-primary"></i>Invest - Plant - Benefit</b>
                            <p className='text-container animate sm:w-[500px] md:text-base text-sm'>
                                Empowering industries, inspiring innovation. At MG Groupe, we are a dynamic conglomerate committed to delivering excellence and sustainability across diverse sectors. Through groundbreaking solutions and a steadfast dedication to quality and environmental responsibility, we drive progress for a brighter, greener future.
                            </p>
                            <section className="flex flex-col gap-3 mt-4">
                                <div className="flex items-center gap-3">
                                    <button><Instagram/></button>
                                    <button><Facebook/></button>
                                    <button><Linkedin/></button>
                                </div>
                            </section>
                        </div>
                        <div className="image-container animate grid grid-cols-3 sm:w-[420px] sm:min-w-[420px] text-gray-300">
                            <aside className="w-full h-[120px] p-4 bg-transparent flex items-center justify-center"><b className="text-lg">Our top picks</b></aside>
                            <aside className="w-full h-[120px] border border-primary">
                                <img src={images[0].image} alt="" className="size-full object-cover" />
                            </aside>
                            <aside className="w-full h-[120px] bg-primary border border-primary p-4">
                                <p className='leading-[0.1rem text-[0.7rem]'>In publishing and graphic design, Lorem ipsum is a placehold</p>
                            </aside>
                            <aside className="w-full h-[120px] border border-primary">
                                <img src={images[4].image} alt="" className="size-full object-cover" />
                            </aside>
                            <aside className="w-full h-[120px] bg-primary border border-primary p-4">
                                <p className='leading-[0.1rem text-[0.7rem]'>In publishing and graphic design, Lorem ipsum is a placehold</p>
                            </aside>
                            <aside className="w-full h-[120px] border border-primary p-4"></aside>
                            <aside className="w-full h-[120px] p-4"></aside>
                            <aside className="w-full h-[120px] p-4"></aside>
                            <aside className="w-full h-[120px] border border-primary">
                                <img src={images[5].image} alt="" className="size-full object-cover" />
                            </aside>
                        </div>
                    </section>}>
                </Content>
                
                <section className="min-h-28 w-full bg-primary text-gray-300 hidden sm:flex sm:flex-row flex-col sm:py-0 py-8 items-center justify-center gap-[2rem]">
                    <div className="flex sm:flex-row flex-col items-center gap-2 opacity-50">
                        <Fish className='-rotate-45 size-7 sm:size-10 animate-pulse sm:mb-0 mb-4'/> 
                        <b style={{fontFamily: 'fantasy'}} className='sm:text-base text-xs text-nowrap'>AQUA-CULTURE</b>
                    </div>
                    <div className="flex sm:flex-row flex-col items-center gap-2 opacity-50">
                        <TreePine className='size-7 sm:size-10 animate-pulse sm:mb-0 mb-4'/> 
                        <b style={{fontFamily: 'fantasy'}} className='sm:text-base text-xs text-nowraps'>POTANY-ENV</b>
                    </div>
                    <div className="flex sm:flex-row flex-col items-center gap-2 opacity-50">
                        <Flower className='-rotate-45 size-7 sm:size-10 animate-pulse sm:mb-0 mb-4'/> 
                        <b style={{fontFamily: 'fantasy'}} className='sm:text-base text-xs text-nowrap'>FLORAL-BEATIFY</b>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Banner