import './App.css';
import Routers from './routes.jsx';

function App() {

  return <>
    <Routers/>
  </>
}

export default App;
