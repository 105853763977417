import { Bolt, Contact, GalleryHorizontal, Home, Magnet, Menu, Plus } from 'lucide-react';
import React, { useState } from 'react'
import { logo } from '../assets';

function Header() {
    const [showMenu, setShowMenu] = useState(false);

    const winLoc = ()=>window.location.href.toLowerCase();

    return <header className="w-full flex items-center justify-between min-h-12">
        <section id="showMenu" className={`fixed w-full h-full top-0 left-0 bg-[#0000008d] z-20 flex md:hidden justify-end transition-all duration-300 ${!showMenu && 'translate-x-[100%]'}`}>
            <aside className={`w-[250px] z-10 flex flex-col p-[1rem] pt-[2rem] bg-primary text-white transition-all duration-300`}>
                <div className="flex justify-between items-center h-10">
                    <aside className="flex items-center px-3 h-9 bg-white rounded-xl text-black text-sm">
                        <h1 className="">MirusGrace</h1>
                    </aside>
                    <button onClick={()=>setShowMenu(!showMenu)} className="size- flex items-center justify-center  text-white rounded-full">
                        <Plus className='rotate-45 size-6'/>
                    </button>
                </div>
                <br />
                <div className={`flex gap-3 items-center h-10 mb-4 transition-all text-sm rounded-2xl hover:pl-4 hover:bg-[#ffffff2e] cursor-pointer ${winLoc().includes('home') && 'bg-[#ffffff2e] pl-4'}`}>
                    <Home className='size-5'/>
                    <p>Home</p>
                </div>
                <div className={`flex gap-3 items-center h-10 mb-4 transition-all text-sm rounded-2xl hover:pl-4 hover:bg-[#ffffff2e] cursor-pointer ${winLoc().includes('about') && 'bg-[#ffffff2e] pl-4'}`}>
                    <Magnet className='size-5'/>
                    <p>About Us</p>
                </div>
                <div className={`flex gap-3 items-center h-10 mb-4 transition-all text-sm rounded-2xl hover:pl-4 hover:bg-[#ffffff2e] cursor-pointer ${winLoc().includes('service') && 'bg-[#ffffff2e] pl-4'}`}>
                    <Bolt className='size-5'/>
                    <p>Service</p>
                </div>
                <div className={`flex gap-3 items-center h-10 mb-4 transition-all text-sm rounded-2xl hover:pl-4 hover:bg-[#ffffff2e] cursor-pointer ${winLoc().includes('gallery') && 'bg-[#ffffff2e] pl-4'}`}>
                    <GalleryHorizontal className='size-5'/>
                    <p>Gallery</p>
                </div>
                <div className={`flex gap-3 items-center h-10 mb-4 transition-all text-sm rounded-2xl hover:pl-4 hover:bg-[#ffffff2e] cursor-pointer ${winLoc().includes('contact') && 'bg-[#ffffff2e] pl-4'}`}>
                    <Contact className='size-5'/>
                    <p>Contact</p>
                </div>
            </aside>
            <div onClick={()=>setShowMenu(!showMenu)} className='absolute size-full'/>
        </section>
        <aside className="size-16">
            <img src={logo} alt='Logo' className='w-full h-full object-contain'/>
        </aside>

        <div className="w-[500px] hidden md:flex items-center justify-between font-semibold text-sm">
            <a href="#home">
                <button className={`px-4 rounded-3xl text-white bg-primary hover:text-white hover:bg-primary transition-all duration-300 shadow-xl h-8`}>Home</button>
            </a>
            <a href="#about">
                <button className={`px-4 rounded-3xl text-gray-800 bg-white hover:text-white hover:bg-primary transition-all duration-300 h-8`}>About Us</button>
            </a>
            <a href="#services">
                <button className={`px-4 rounded-3xl text-gray-800 bg-white hover:text-white hover:bg-primary transition-all duration-300 h-8`}>Services</button>
            </a>
            <a href="#gallery">
                <button className={`px-4 rounded-3xl text-gray-800 bg-white hover:text-white hover:bg-primary transition-all duration-300 h-8`}>Gallery</button>
            </a>
            <a href="#contact">
                <button className={`px-4 rounded-3xl text-gray-800 bg-white hover:text-white hover:bg-primary transition-all duration-300 h-8`}>Contact Us</button>
            </a>
        </div>
        <button onClick={()=>setShowMenu(!showMenu)} className="rounded-full size-8 shadow-xl flex md:hidden items-center justify-center bg-white text-black">
            {showMenu ? <Plus className='rotate-45 size-4'/> : <Menu className='size-4'/>}
        </button>
    </header>
}

export default Header