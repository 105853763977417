import React from 'react'

function Modal({name, close, content}) {
    if(name){
        return (
            <div className='fixed top-0 left-0 z-50 w-screen h-screen flex justify-center items-center bg-[#0000007d]'>
                <section className="w-[95%] md:w-auto min-w-[95%] md:min-w-[80%] xl:min-w-[400px] min-h-[300px] max-h-[90dvh] flex flex-col z-10 bg-white p-4 rounded-xl overflow-hidden overflow-y-auto">
                    <div className="h-8 flex items-center justify-between sticky top-0">
                        <h1 className="text-xl font-bold">{name}</h1>
                        <i onClick={()=>close()} className="fa fa-times"></i>
                    </div>
                    {content}
                </section>
                <div onClick={()=>close()} className='absolute w-full h-full'/>
            </div>
        )
    }
    return null;
}

export default Modal