import { useEffect, useState } from 'react';


const useDevice = () => {
    const [os, setOs] = useState('');

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
        setOs('Android');
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        setOs('iOS');
        } else {
        setOs('Unknown');
        }
    }, []);

    return {os}
};


export default useDevice;